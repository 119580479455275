.rsce_joblinks {
  // margin: 0 auto;
  // max-width: 728px;
}
.rsce_joblinklist {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a.link {
    margin-right: 0;
    margin-bottom: 56px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .rsce_joblinklist {
    a.link {
      width: 45%;
    }
  }
}
@media only screen and (min-width: 992px) {
  .rsce_joblinklist {
    a.link {
      width: 30%;
    }
  }
}
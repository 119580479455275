.rsce_headline_copy {
  position: relative;

  h1, h2 {
    margin-top: 0;
  }
  .copy--large p {
    font-weight: 700;
    font-size: to-rem(20);
    line-height: to-rem(32);
  }

  &.rsce_headline_copy--clouds::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.rsce_headline_copy--clouds {
  h2, p {
    max-width: 730px;
  }
}
.rsce_headline_copy_cloud1 {
  position: absolute;
  top: -30px;
  left: 0;
  line-height: 0;
  margin: 0;
  width: clamp(to-rem(200), clamp-element(200, 375, 350, 1200), to-rem(350));
  z-index: -1;
}
.rsce_headline_copy_cloud2 {
  position: absolute;
  bottom: -30px;
  right: 0;
  line-height: 0;
  margin: 0;
  width: clamp(to-rem(200), clamp-element(200, 375, 350, 1200), to-rem(350));
  z-index: -1;
  transform: rotate(-180deg);
}

.rsce_headline_copy--half {
  position: relative;
}
.headline_copy_half {
  padding-right: clamp(to-rem(80), clamp-element(80, 375, 140, 1200), to-rem(140));
  padding-top: clamp(to-rem(100), clamp-element(100, 375, 150, 1200), to-rem(150));
  padding-bottom: clamp(to-rem(100), clamp-element(100, 375, 150, 1200), to-rem(150));
}
.rsce_headline_copy_half1,
.rsce_headline_copy_half2,
.rsce_headline_copy_half3 {
  position: absolute;
  line-height: 0;
  margin: 0;
  width: clamp(to-rem(100), clamp-element(100, 375, 166, 1200), to-rem(166));
  z-index: -1;
  transition: transform 0.15s ease-in-out;
}
.rsce_headline_copy_half1 {
  top: 0;
  left: 50%;
  margin-left: -150px;
  transform: rotate(45deg);
}
.rsce_headline_copy_half2 {
  top: 50%;
  margin-top: clamp(to-rem(-45), clamp-element(-45, 375, -130, 1200), to-rem(-130));;
  right: 0;
  width: clamp(to-rem(100), clamp-element(100, 375, 166, 1200), to-rem(166));
  transform: rotate(-90deg);
}
.rsce_headline_copy_half3 {
  bottom: 0;
  left: 50%;
  margin-left: -50px;
  transform: rotate(0deg);
}
.rsce_headline_copy--half:hover {
  .rsce_headline_copy_half1 {
    transform: rotate(90deg)
  }
  .rsce_headline_copy_half2 {
    transform: rotate(-45deg);
  }
  .rsce_headline_copy_half3 {
    transform: rotate(-90deg);
  }
}
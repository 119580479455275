/* -------------------------------------------------------------
//  Typo
// -----------------------------------------------------------*/

.font-size-display-1 {
  font-family: $font-desktop-display-1-font-family, Helvetica, Arial;
  font-weight: $font-desktop-display-1-font-weight;
  font-size: clamp(to-rem($font-mobile-display-1-font-size), clamp-element($font-mobile-display-1-font-size, 375, $font-desktop-display-1-font-size, 1440), to-rem($font-desktop-display-1-font-size));
  line-height: clamp(to-rem($font-mobile-display-1-line-height), clamp-element($font-mobile-display-1-line-height, 375, $font-desktop-display-1-line-height, 1440), to-rem($font-desktop-display-1-line-height));
  margin-top: clamp(to-rem($spacing-display-1-mobile-top), clamp-element($spacing-display-1-mobile-top, 375, $spacing-display-1-desktop-top, 1440), to-rem($spacing-display-1-desktop-top));
  margin-bottom: clamp(to-rem($spacing-display-1-mobile-bottom), clamp-element($spacing-display-1-mobile-bottom, 375, $spacing-display-1-desktop-bottom, 1440), to-rem($spacing-display-1-desktop-bottom));
  max-width: 825px;
}

.font-size-display-2 {
  font-family: $font-desktop-display-2-font-family, Helvetica, Arial;
  font-weight: $font-desktop-display-2-font-weight;
  font-size: clamp(to-rem($font-mobile-display-2-font-size), clamp-element($font-mobile-display-2-font-size, 375, $font-desktop-display-2-font-size, 1440), to-rem($font-desktop-display-2-font-size));
  line-height: clamp(to-rem($font-mobile-display-2-line-height), clamp-element($font-mobile-display-2-line-height, 375, $font-desktop-display-2-line-height, 1440), to-rem($font-desktop-display-2-line-height));
  margin-top: clamp(to-rem($spacing-display-2-mobile-top), clamp-element($spacing-display-2-mobile-top, 375, $spacing-display-2-desktop-top, 1440), to-rem($spacing-display-2-desktop-top));
  margin-bottom: clamp(to-rem($spacing-display-2-mobile-bottom), clamp-element($spacing-display-2-mobile-bottom, 375, $spacing-display-2-desktop-bottom, 1440), to-rem($spacing-display-2-desktop-bottom));
  max-width: 825px;
}

h1, .h1 {
  font-family: $font-desktop-h1-font-family, Helvetica, Arial;
  font-weight: $font-desktop-h1-font-weight;
  font-size: clamp(to-rem($font-mobile-h1-font-size), clamp-element($font-mobile-h1-font-size, 375, $font-desktop-h1-font-size, 1440), to-rem($font-desktop-h1-font-size));
  line-height: clamp(to-rem($font-mobile-h1-line-height), clamp-element($font-mobile-h1-line-height, 375, $font-desktop-h1-line-height, 1440), to-rem($font-desktop-h1-line-height));
  margin-top: clamp(to-rem($spacing-h1-mobile-top), clamp-element($spacing-h1-mobile-top, 375, $spacing-h1-desktop-top, 1440), to-rem($spacing-h1-desktop-top));
  margin-bottom: clamp(to-rem($spacing-h1-mobile-bottom), clamp-element($spacing-h1-mobile-bottom, 375, $spacing-h1-desktop-bottom, 1440), to-rem($spacing-h1-desktop-bottom));
  max-width: 825px;
}

h2, .h2 {
  font-family: $font-desktop-h2-font-family, Helvetica, Arial;
  font-weight: $font-desktop-h2-font-weight;
  font-size: clamp(to-rem($font-mobile-h2-font-size), clamp-element($font-mobile-h2-font-size, 375, $font-desktop-h2-font-size, 1440), to-rem($font-desktop-h2-font-size));
  line-height: clamp(to-rem($font-mobile-h2-line-height), clamp-element($font-mobile-h2-line-height, 375, $font-desktop-h2-line-height, 1440), to-rem($font-desktop-h2-line-height));
  margin-top: clamp(to-rem($spacing-h2-mobile-top), clamp-element($spacing-h2-mobile-top, 375, $spacing-h2-desktop-top, 1440), to-rem($spacing-h2-desktop-top));
  margin-bottom: clamp(to-rem($spacing-h2-mobile-bottom), clamp-element($spacing-h2-mobile-bottom, 375, $spacing-h2-desktop-bottom, 1440), to-rem($spacing-h2-desktop-bottom));
  max-width: 825px;
}

h3, .h3 {
  font-family: $font-desktop-h3-font-family, Helvetica, Arial;
  font-weight: $font-desktop-h3-font-weight;
  font-size: clamp(to-rem($font-mobile-h3-font-size), clamp-element($font-mobile-h3-font-size, 375, $font-desktop-h3-font-size, 1440), to-rem($font-desktop-h3-font-size));
  line-height: clamp(to-rem($font-mobile-h3-line-height), clamp-element($font-mobile-h3-line-height, 375, $font-desktop-h3-line-height, 1440), to-rem($font-desktop-h3-line-height));
  margin-top: clamp(to-rem($spacing-h3-mobile-top), clamp-element($spacing-h3-mobile-top, 375, $spacing-h3-desktop-top, 1440), to-rem($spacing-h3-desktop-top));
  margin-bottom: clamp(to-rem($spacing-h3-mobile-bottom), clamp-element($spacing-h3-mobile-bottom, 375, $spacing-h3-desktop-bottom, 1440), to-rem($spacing-h3-desktop-bottom));
  max-width: 825px;
}

h4, .h4 {
  font-family: $font-desktop-h4-font-family, Helvetica, Arial;
  font-weight: $font-desktop-h4-font-weight;
  font-size: clamp(to-rem($font-mobile-h4-font-size), clamp-element($font-mobile-h4-font-size, 375, $font-desktop-h4-font-size, 1440), to-rem($font-desktop-h4-font-size));
  line-height: clamp(to-rem($font-mobile-h4-line-height), clamp-element($font-mobile-h4-line-height, 375, $font-desktop-h4-line-height, 1440), to-rem($font-desktop-h4-line-height));
  margin-top: clamp(to-rem($spacing-h4-mobile-top), clamp-element($spacing-h4-mobile-top, 375, $spacing-h4-desktop-top, 1440), to-rem($spacing-h4-desktop-top));
  margin-bottom: clamp(to-rem($spacing-h4-mobile-bottom), clamp-element($spacing-h4-mobile-bottom, 375, $spacing-h4-desktop-bottom, 1440), to-rem($spacing-h4-desktop-bottom));
  max-width: 825px;
}

h5, .h5 {
  font-family: $font-desktop-h5-font-family, Helvetica, Arial;
  font-weight: $font-desktop-h5-font-weight;
  font-size: clamp(to-rem($font-mobile-h5-font-size), clamp-element($font-mobile-h5-font-size, 375, $font-desktop-h5-font-size, 1440), to-rem($font-desktop-h5-font-size));
  line-height: clamp(to-rem($font-mobile-h5-line-height), clamp-element($font-mobile-h5-line-height, 375, $font-desktop-h5-line-height, 1440), to-rem($font-desktop-h5-line-height));
  margin-top: clamp(to-rem($spacing-h5-mobile-top), clamp-element($spacing-h5-mobile-top, 375, $spacing-h5-desktop-top, 1440), to-rem($spacing-h5-desktop-top));
  margin-bottom: clamp(to-rem($spacing-h5-mobile-bottom), clamp-element($spacing-h5-mobile-bottom, 375, $spacing-h5-desktop-bottom, 1440), to-rem($spacing-h5-desktop-bottom));
  max-width: 825px;
}

h6, .h6 {
  font-family: $font-desktop-h6-font-family, Helvetica, Arial;
  font-weight: $font-desktop-h6-font-weight;
  font-size: clamp(to-rem($font-mobile-h6-font-size), clamp-element($font-mobile-h6-font-size, 375, $font-desktop-h6-font-size, 1440), to-rem($font-desktop-h6-font-size));
  line-height: clamp(to-rem($font-mobile-h6-line-height), clamp-element($font-mobile-h6-line-height, 375, $font-desktop-h6-line-height, 1440), to-rem($font-desktop-h6-line-height));
  margin-top: clamp(to-rem($spacing-h6-mobile-top), clamp-element($spacing-h6-mobile-top, 375, $spacing-h6-desktop-top, 1440), to-rem($spacing-h6-desktop-top));
  margin-bottom: clamp(to-rem($spacing-h6-mobile-bottom), clamp-element($spacing-h6-mobile-bottom, 375, $spacing-h6-desktop-bottom, 1440), to-rem($spacing-h6-desktop-bottom));
  max-width: 825px;
}

.col-12 > h1,
.col-12 > h2,
.col-12 > h3,
.col-12 > h4,
.col-12 > h5,
.col-12 > h6 {
  margin-top: 0; // resets margin of first headline
}

h1.center, .h1.center, h2.center, .h2.center, h3.center, .h3.center, h4.center, .h4.center, h5.center, .h5.center, h6.center, .h6.center {
  margin-left: auto;
  margin-right: auto;
}
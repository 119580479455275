/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.button {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $button-border-color;
  border-radius: 4px;
  color: $button-font-color;
  cursor: pointer;
  display: inline-flex;
  font-size: to-rem($button-font-size);
  font-weight: 400;
  line-height: to-rem($button-line-height);
  margin-bottom: to-rem($button-margin-bottom);
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: $button-font-color-hover;
  }

  // Buttonanimation
  .buttoninner {
    // display: inline-block;
    // transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
    // z-index: 1;
  }
  // span {
  //   border-radius: 50%;
  //   background-color: $color-primary;
  //   display: block;
  //   position: absolute;
  //   height: 0;
  //   top: 50%;
  //   left: 50%;
  //   transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  //   -webkit-transform: translate(-50%, -50%);
  //   transform: translate(-50%, -50%);
  //   width: 0;
  // }
  &:hover {
    .buttoninner {
      color: $color-white;
      text-decoration: none;
    }
    // span {
    //   height: 562.5px;
    //   width: 225%;
    // }
  }
  &:focus-visible {
    border: 1px solid $button-primary-border-color-hover;
    .buttoninner {
      color: $color-white;
      text-decoration: none;
    }
    // span {
    //   height: 562.5px;
    //   width: 225%;
    // }
  }

  /* Button Secondary */
  &.button--secondary {
    background-color: transparent;
    border: 1px solid $color-black-70;
    color: $color-black-70;
  }
  &.button--secondary:hover {
    border: 1px solid $color-black-90;
    color: $color-black-90;
  }
  &.button--secondary:focus-visible {
    border: 1px solid $color-white;
    outline: 3px solid $color-primary-light;
  }

  /* Button Menu */
  &.button--menu {
    background-color: transparent;
    border: 1px solid $color-white;
    color: $color-white;
    margin-top: 2rem;
  }
  &.button--menu:hover {
    border: 1px solid $color-yellow-dark;
    color: $color-yellow-dark;
  }
  &.button--menu:focus-visible {
    border: 1px solid $color-yellow-dark;
    outline: 3px solid $color-yellow-normal;
  }
}
.button--menu {
  opacity: 0;
}
.navmain.is-open .button--menu {
  animation: fadein .3s 1 .95s normal forwards;
}
/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
img {
  height: auto;
  margin-bottom: to-rem($font-medium-line-height);
  width: 100%;
}
.image_container {
  margin: 0;
}
.arrow_top {
  cursor: pointer;
  position: absolute;
  right: 0;
  transform: translate(0px, -185px);
  width: 30px;
  top: 0;
}
.arrow_down {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .arrow_top {
    transform: translate(30px, -185px);
  }
  .arrow_down {
    bottom: 136px;
    cursor: pointer;
    display: block;
    left: 50%;
    margin-left: -15px;
    position: absolute;
    width: 30px;
  }
}
@media only screen and (min-width: 1400px) {
  .arrow_top {
    transform: translate(30px, -215px);
  }
}
@media only screen and (min-width: 1800px) {
  .arrow_top {
    transform: translate(30px, -235px);
  }
}

/* -------------------------------------------------------------
//  Map
// -----------------------------------------------------------*/
.map_container {
  margin-top: 2.5em;
  margin-bottom: 1em;
}
.map__scaler {display:none;}
.map__wrapper {
  position: relative;
  transform: scale(0.45);
  margin-top: -40px;
}
.map__list {display: block;}

@media only screen and (min-width: 641px) {
  .map__list {display: none;}
  .map__scaler {
    display:block;
    height: 430px;
  }
  .map__wrapper {
    height: 950px;
    position: relative;
    transform-origin: top left;
    width: 1140px;
  }
  .map__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 950px;
    width: 1140px;
  }
  .marker {
    position: absolute;
  }
  .marker--kita-seeblick {
    left: 378px;
    top: 107px;
    width: 230px;
  }
  .marker--hort-seeblick {
    left: 327px;
    top: 230px;
    width: 170px;
  }
  .marker--kita-siebenschlaefer {
    left: 217px;
    top: 629px;
    width: 200px;
  }
  .marker--reuterhort {
    left: 545px;
    top: 513px;
    width: 140px;
  }
  .marker--spielhaus {
    left: 480px;
    top: 684px;
    width: 270px;

    .marker__link--hort {
      width: 260px;
    }
  }
  .marker--sonnenschein {
    left: 657px;
    top: 272px;
    width: 230px;
  }
  .marker--hansehort {
    left: 515px;
    top: 614px;
    width: 170px;
  }
  .marker__image {
    cursor: pointer;
    height: 83px;
    float: left;
    width: 60px;
  }
  .marker__link {
    border: 0 none;
    display: inline-block;
    font-style: normal;
    font-size: 1.2em;
    text-decoration: none;
    transition: scale .15s ease-in-out;
  }
  .marker__link img {
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  .marker__link:hover,
  .marker__link:focus {
    border: none;
    text-decoration: none;
    transform: scale(1.1);
  }
  .marker.active .marker__link {
    display: inline-block;
  }
  .marker__link:before {
    display: none;
  }
  .marker__link--kita {

  }
  .marker__link--tagesgruppe {

  }
  .marker__link--hort {

  }
  .marker--right .marker__image,
  .marker--right .marker__link {
    float: right;
  }
}
@media only screen and (min-width: 768px) {
  .map__wrapper {
    transform: scale(0.58);
  }
  .map__scaler {
    height: 552px;
  }
}
@media only screen and (min-width: 992px) {
  .map__wrapper {
    transform: scale(0.79);
  }
  .map__scaler {
    height:751px;
  }
}
@media only screen and (min-width: 1200px) {
  .map__wrapper {
    transform: scale(0.95);
  }
  .map__scaler {
    height:903px;
  }
}
@media only screen and (min-width: 1400px) {
  .map__wrapper {
    transform: scale(1);
  }
  .map__scaler {
    height:950px;
  }
}

.scale-left-bottom {
  transform-origin: left bottom;
}
.scale-right-bottom {
  transform-origin: right bottom;
}
.scale-left-center {
  transform-origin: left center;
}
.scale-right-center {
  transform-origin: right center;
}
.scale-left-top {
  transform-origin: left top;
}

.downloadlist__item {
  background: transparent url(../img/btn_download.svg) no-repeat left top;
  background-size: 50px;
  border-bottom: none;
  display: inline-block;
  font-family: Nunito, Helvetica, Arial;
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 20px;
  min-height: 50px;
  padding-left: 75px;
  padding-top: 8px;
  div {
    padding-right: 30px;
  }
}
.downloadlist__link {
  border: none !important;
  text-decoration: none !important;
}
.downloadlist__link:hover {
  border-bottom: none;
}
@media only screen and (min-width: 992px) {
  .downloadlist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    margin-top: 2rem;
  }
  .downloadlist__item {
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1200px) {
  .downloadlist {
    margin-top: 4.7rem;
  }
  .downloadlist__item {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 26px;
  }
}
.toggle_download {
  background: transparent url(../img/bg_arrow_down.svg) no-repeat center;
  background-size: 7px 14px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  transform: rotate(90deg);
  margin-left: 10px;
  width: 20px;
}
.toggle_download.active {
  transform: rotate(-90deg);
}
.download_description {
  height: 0;
  overflow: hidden;
}
.download_description.show {
  height: auto;
}
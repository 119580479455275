/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    margin-bottom: to-rem($section-margin);
  }

  &::after {
    margin-top: to-rem($section-margin);
  }

  &--light {
    background-color: $section-background-light;
  }
  &--dark {
    background-color: $section-background-dark;
  }

  &--padding-top {
    border-bottom: none;
  }
  &--padding-top::after {
    margin: 0;
  }
  &--padding-none {
    border: none;
  }
  &--padding-none::after {
    display: none;
  }
  &--padding-none::before {
    display: none;
  }
  &--padding-bottom {
    border-top: none;
  }
  &--padding-bottom::before {
    display: none;
  }

  &--color-light-beige {
    background-color: $color-bg-beige-bright;
    background-image: url(../img/bg_lightb_top.svg), url(../img/bg_lightb_bottom.svg);
    background-size: 500px auto, 500px auto;
    background-position: center top -1px, center bottom -1px;
    background-repeat: repeat-x, repeat-x;
  }
  &--color-light-blue {
    background-color: $color-bg-blue-bright;
    background-image: url(../img/bg_lightb_top.svg), url(../img/bg_lightb_bottom.svg);
    background-size: 500px auto, 500px auto;
    background-position: center top -1px, center bottom -1px;
    background-repeat: repeat-x, repeat-x;
  }
}
@media only screen and (min-width: 992px) {
  .section {
    &::before {
      margin-bottom: to-rem(85);
    }
    &::after {
      margin-top: to-rem(85);
    }
    &--color-light-beige {
      background-size: 1000px auto, 1000px auto;
    }
    &--color-light-blue {
      background-size: 1000px auto, 1000px auto;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .section {
    &::before {
      margin-bottom: to-rem(120);
    }
  
    &::after {
      margin-top: to-rem(120);
    }
    &--color-light-beige {
      background-size: 1439px auto, 1439px auto;
    }
    &--color-light-blue {
      background-size: 1439px auto, 1439px auto;
    }
  }
}
/* -------------------------------------------------------------
//  Logos
// ---------------------------------------------------------- */
.logo {
  display: block;
  margin: 0;
  width: 100px;
  transition: transform 0.15s ease-in;
  transform: translate(0, 0);
  z-index: 5;

  svg {
    height: auto;
    margin-bottom: 0;
    width: 100px;
  }

  &.dark {
    .logocolor {
      fill: $color-white;
    }
  }
}
.logocolor {
  transition: fill 0.1s ease-in;
}
@media only screen and (min-width: 992px) {
  .logo {
    width: 172px;

    svg {
      width: 172px;
    }
  }
}

/* Sticky Header */
header.sticky {
  .logocolor {
    fill: transparent !important;
  }
  .logo {
    transform: translate(0, -10px);
  }
}

/* Footer */
.footer_logo {
  height: 25px;
  width: auto;
}
@media only screen and (min-width: 992px) {
  .footer_logo {
    height: 35px;
  }
}
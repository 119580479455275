.rsce_timetable {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 3rem;
}
.timetable__item {
  border-top: 1px solid #c4c4c4;
}
@media only screen and (min-width: 576px) {
  .timetable__item {
    display: grid;
    grid-template-columns: 130px auto;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
@media only screen and (min-width: 768px) {
  .timetable__item {
    grid-template-columns: 170px auto;
  }
}
@media only screen and (min-width: 1200px) {
  .timetable__item {
    grid-template-columns: 200px auto;
  }
}
@media only screen and (min-width: 1400px) {
  .timetable__item {
    grid-template-columns: 220px auto;
  }
}
.staff__image img {
  border-radius: 31% 69% 55% 45%/28% 52% 48% 72%;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.staff__copy {
  align-items: center;
  display: flex;
  flex-direction: column;

  span {
    display: block;
    font-size: 18px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .rsce_staff {
    align-items: center;
    display: flex;
    flex-direction: initial;
  }
  .staff__image {
    flex-shrink: 0;
    width: 50%;

    img {
      margin-right: 20px;
    }
  }
  .staff__copy {
    align-items: flex-start;
    flex-shrink: 0;
    padding-left: 20px;
    width: 50%;
  }
}
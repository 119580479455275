
// Do not edit directly
// Generated on Fri, 26 Aug 2022 09:23:07 GMT

$sizes-xxsmall: 4;
$sizes-xsmall: 8;
$sizes-small: 12;
$sizes-medium: 16;
$sizes-large: 24;
$sizes-xlarge: 32;
$sizes-xxlarge: 48;
$sizes-section: 64;
$spacing-display-1-desktop-top: 24;
$spacing-display-1-desktop-right: 0;
$spacing-display-1-desktop-bottom: 48;
$spacing-display-1-desktop-left: 0;
$spacing-display-2-desktop-top: 24;
$spacing-display-2-desktop-right: 0;
$spacing-display-2-desktop-bottom: 48;
$spacing-display-2-desktop-left: 0;
$spacing-h1-desktop-top: 24;
$spacing-h1-desktop-right: 0;
$spacing-h1-desktop-bottom: 24;
$spacing-h1-desktop-left: 0;
$spacing-h2-desktop-top: 24;
$spacing-h2-desktop-right: 0;
$spacing-h2-desktop-bottom: 24;
$spacing-h2-desktop-left: 0;
$spacing-h3-desktop-top: 24;
$spacing-h3-desktop-right: 0;
$spacing-h3-desktop-bottom: 16;
$spacing-h3-desktop-left: 0;
$spacing-h4-desktop-top: 24;
$spacing-h4-desktop-right: 0;
$spacing-h4-desktop-bottom: 16;
$spacing-h4-desktop-left: 0;
$spacing-h5-desktop-top: 24;
$spacing-h5-desktop-right: 0;
$spacing-h5-desktop-bottom: 16;
$spacing-h5-desktop-left: 0;
$spacing-h6-desktop-top: 24;
$spacing-h6-desktop-right: 0;
$spacing-h6-desktop-bottom: 0;
$spacing-h6-desktop-left: 0;
$spacing-display-1-mobile-top: 24;
$spacing-display-1-mobile-right: 0;
$spacing-display-1-mobile-bottom: 48;
$spacing-display-1-mobile-left: 0;
$spacing-display-2-mobile-top: 24;
$spacing-display-2-mobile-right: 0;
$spacing-display-2-mobile-bottom: 48;
$spacing-display-2-mobile-left: 0;
$spacing-h1-mobile-top: 24;
$spacing-h1-mobile-right: 0;
$spacing-h1-mobile-bottom: 24;
$spacing-h1-mobile-left: 0;
$spacing-h2-mobile-top: 24;
$spacing-h2-mobile-right: 0;
$spacing-h2-mobile-bottom: 24;
$spacing-h2-mobile-left: 0;
$spacing-h3-mobile-top: 24;
$spacing-h3-mobile-right: 0;
$spacing-h3-mobile-bottom: 0;
$spacing-h3-mobile-left: 0;
$spacing-h4-mobile-top: 24;
$spacing-h4-mobile-right: 0;
$spacing-h4-mobile-bottom: 0;
$spacing-h4-mobile-left: 0;
$spacing-h5-mobile-top: 24;
$spacing-h5-mobile-right: 0;
$spacing-h5-mobile-bottom: 0;
$spacing-h5-mobile-left: 0;
$spacing-h6-mobile-top: 24;
$spacing-h6-mobile-right: 0;
$spacing-h6-mobile-bottom: 0;
$spacing-h6-mobile-left: 0;
$color-black: #000000;
$color-black-90: #1c1c28;
$color-black-80: #28293d;
$color-black-70: #555770;
$color-black-60: #8f90a6;
$color-black-50: #c7c9d9;
$color-black-40: #d8d9e3;
$color-black-30: #dcdde5;
$color-black-20: #e1e2eb;
$color-black-10: #e9e9f0;
$color-black-05: #eeeff5;
$color-black-01: #fafafc;
$color-white: #ffffff;
$color-primary-dark: #da6a3f;
$color-primary: #e77244;
$color-primary-light: #ffaa8a;
$color-accent-dark: #5c5651;
$color-accent: #756e67;
$color-accent-light: #958c83;
$color-danger-dark: #e53535;
$color-danger: #ff3b3b;
$color-danger-light: #ff8080;
$color-info-dark: #03b4d5;
$color-info: #03c7ec;
$color-info-light: #3ddffe;
$color-success-dark: #05a660;
$color-success: #06c270;
$color-success-light: #57eba1;
$color-warning-dark: #e5b800;
$color-warning: #ffcc00;
$color-warning-light: #fded72;
$color-bg-beige-bright: #f6f3f0;
$color-bg-blue-bright: #f4f6f8;
$color-orange-dark: #ff8a5e;
$color-orange-normal: #ffaa8a;
$color-orange-light: #ffc1af;
$color-blue-dark: #4269ab;
$color-blue-normal: #6f91cb;
$color-blue-light: #d9e2f2;
$color-yellow-dark: #f2d058;
$color-yellow-normal: #fbe186;
$color-yellow-light: #fff1a9;
$color-green-dark: #5bb789;
$color-green-normal: #97d4b5;
$color-green-light: #cdefde;
$color-logoblau: #284f96;
$font-xxlarge-bold-font-size: 24;
$font-xxlarge-bold-text-decoration: none;
$font-xxlarge-bold-font-family: Nunito;
$font-xxlarge-bold-font-weight: 800;
$font-xxlarge-bold-font-style: normal;
$font-xxlarge-bold-font-stretch: normal;
$font-xxlarge-bold-font-style-old: ExtraBold;
$font-xxlarge-bold-letter-spacing: 0;
$font-xxlarge-bold-line-height: 32;
$font-xxlarge-bold-paragraph-indent: 0;
$font-xxlarge-bold-paragraph-spacing: 0;
$font-xxlarge-bold-text-case: none;
$font-xxlarge-font-size: 24;
$font-xxlarge-text-decoration: none;
$font-xxlarge-font-family: Lato;
$font-xxlarge-font-weight: 800;
$font-xxlarge-font-style: normal;
$font-xxlarge-font-stretch: normal;
$font-xxlarge-font-style-old: ExtraBold;
$font-xxlarge-letter-spacing: 0;
$font-xxlarge-line-height: 32;
$font-xxlarge-paragraph-indent: 0;
$font-xxlarge-paragraph-spacing: 0;
$font-xxlarge-text-case: none;
$font-xlarge-bold-font-size: 20;
$font-xlarge-bold-text-decoration: none;
$font-xlarge-bold-font-family: Lato;
$font-xlarge-bold-font-weight: 700;
$font-xlarge-bold-font-style: normal;
$font-xlarge-bold-font-stretch: normal;
$font-xlarge-bold-font-style-old: Bold;
$font-xlarge-bold-letter-spacing: 0;
$font-xlarge-bold-line-height: 32;
$font-xlarge-bold-paragraph-indent: 0;
$font-xlarge-bold-paragraph-spacing: 0;
$font-xlarge-bold-text-case: none;
$font-xlarge-font-size: 20;
$font-xlarge-text-decoration: none;
$font-xlarge-font-family: Inter;
$font-xlarge-font-weight: 500;
$font-xlarge-font-style: normal;
$font-xlarge-font-stretch: normal;
$font-xlarge-font-style-old: Medium;
$font-xlarge-letter-spacing: 0;
$font-xlarge-line-height: 32;
$font-xlarge-paragraph-indent: 0;
$font-xlarge-paragraph-spacing: 0;
$font-xlarge-text-case: none;
$font-large-bold-font-size: 18;
$font-large-bold-text-decoration: none;
$font-large-bold-font-family: Lato;
$font-large-bold-font-weight: 700;
$font-large-bold-font-style: normal;
$font-large-bold-font-stretch: normal;
$font-large-bold-font-style-old: Bold;
$font-large-bold-letter-spacing: 0;
$font-large-bold-line-height: 32;
$font-large-bold-paragraph-indent: 0;
$font-large-bold-paragraph-spacing: 0;
$font-large-bold-text-case: none;
$font-large-font-size: 18;
$font-large-text-decoration: none;
$font-large-font-family: Lato;
$font-large-font-weight: 400;
$font-large-font-style: normal;
$font-large-font-stretch: normal;
$font-large-font-style-old: Regular;
$font-large-letter-spacing: 0;
$font-large-line-height: 32;
$font-large-paragraph-indent: 0;
$font-large-paragraph-spacing: 0;
$font-large-text-case: none;
$font-medium-bold-font-size: 16;
$font-medium-bold-text-decoration: none;
$font-medium-bold-font-family: Inter;
$font-medium-bold-font-weight: 600;
$font-medium-bold-font-style: normal;
$font-medium-bold-font-stretch: normal;
$font-medium-bold-font-style-old: Semi Bold;
$font-medium-bold-letter-spacing: 0;
$font-medium-bold-line-height: 24;
$font-medium-bold-paragraph-indent: 0;
$font-medium-bold-paragraph-spacing: 0;
$font-medium-bold-text-case: none;
$font-medium-font-size: 16;
$font-medium-text-decoration: none;
$font-medium-font-family: Inter;
$font-medium-font-weight: 400;
$font-medium-font-style: normal;
$font-medium-font-stretch: normal;
$font-medium-font-style-old: Regular;
$font-medium-letter-spacing: 0;
$font-medium-line-height: 24;
$font-medium-paragraph-indent: 0;
$font-medium-paragraph-spacing: 0;
$font-medium-text-case: none;
$font-small-bold-font-size: 14;
$font-small-bold-text-decoration: none;
$font-small-bold-font-family: Lato;
$font-small-bold-font-weight: 700;
$font-small-bold-font-style: normal;
$font-small-bold-font-stretch: normal;
$font-small-bold-font-style-old: Bold;
$font-small-bold-letter-spacing: 0;
$font-small-bold-line-height: 24;
$font-small-bold-paragraph-indent: 0;
$font-small-bold-paragraph-spacing: 0;
$font-small-bold-text-case: none;
$font-small-font-size: 14;
$font-small-text-decoration: none;
$font-small-font-family: Lato;
$font-small-font-weight: 400;
$font-small-font-style: normal;
$font-small-font-stretch: normal;
$font-small-font-style-old: Regular;
$font-small-letter-spacing: 0;
$font-small-line-height: 24;
$font-small-paragraph-indent: 0;
$font-small-paragraph-spacing: 0;
$font-small-text-case: none;
$font-xsmall-bold-font-size: 14;
$font-xsmall-bold-text-decoration: none;
$font-xsmall-bold-font-family: Inter;
$font-xsmall-bold-font-weight: 700;
$font-xsmall-bold-font-style: normal;
$font-xsmall-bold-font-stretch: normal;
$font-xsmall-bold-font-style-old: Bold;
$font-xsmall-bold-letter-spacing: 0.75;
$font-xsmall-bold-line-height: 24;
$font-xsmall-bold-paragraph-indent: 0;
$font-xsmall-bold-paragraph-spacing: 0;
$font-xsmall-bold-text-case: none;
$font-xsmall-font-size: 12;
$font-xsmall-text-decoration: none;
$font-xsmall-font-family: Inter;
$font-xsmall-font-weight: 500;
$font-xsmall-font-style: normal;
$font-xsmall-font-stretch: normal;
$font-xsmall-font-style-old: Medium;
$font-xsmall-letter-spacing: 0.75;
$font-xsmall-line-height: 24;
$font-xsmall-paragraph-indent: 0;
$font-xsmall-paragraph-spacing: 0;
$font-xsmall-text-case: none;
$font-xxsmall-bold-font-size: 10;
$font-xxsmall-bold-text-decoration: none;
$font-xxsmall-bold-font-family: Inter;
$font-xxsmall-bold-font-weight: 700;
$font-xxsmall-bold-font-style: normal;
$font-xxsmall-bold-font-stretch: normal;
$font-xxsmall-bold-font-style-old: Bold;
$font-xxsmall-bold-letter-spacing: 0.75;
$font-xxsmall-bold-line-height: 24;
$font-xxsmall-bold-paragraph-indent: 0;
$font-xxsmall-bold-paragraph-spacing: 0;
$font-xxsmall-bold-text-case: none;
$font-xxsmall-font-size: 10;
$font-xxsmall-text-decoration: none;
$font-xxsmall-font-family: Inter;
$font-xxsmall-font-weight: 500;
$font-xxsmall-font-style: normal;
$font-xxsmall-font-stretch: normal;
$font-xxsmall-font-style-old: Medium;
$font-xxsmall-letter-spacing: 0.75;
$font-xxsmall-line-height: 24;
$font-xxsmall-paragraph-indent: 0;
$font-xxsmall-paragraph-spacing: 0;
$font-xxsmall-text-case: none;
$font-button-lead-font-size: 18;
$font-button-lead-text-decoration: none;
$font-button-lead-font-family: Lato;
$font-button-lead-font-weight: 500;
$font-button-lead-font-style: normal;
$font-button-lead-font-stretch: normal;
$font-button-lead-font-style-old: Medium;
$font-button-lead-letter-spacing: 0.75;
$font-button-lead-line-height: 32;
$font-button-lead-paragraph-indent: 0;
$font-button-lead-paragraph-spacing: 0;
$font-button-lead-text-case: none;
$font-button-font-size: 16;
$font-button-text-decoration: none;
$font-button-font-family: Lato;
$font-button-font-weight: 600;
$font-button-font-style: normal;
$font-button-font-stretch: normal;
$font-button-font-style-old: SemiBold;
$font-button-letter-spacing: 0.75;
$font-button-line-height: 24;
$font-button-paragraph-indent: 0;
$font-button-paragraph-spacing: 0;
$font-button-text-case: none;
$font-button-small-font-size: 14;
$font-button-small-text-decoration: none;
$font-button-small-font-family: Inter;
$font-button-small-font-weight: 600;
$font-button-small-font-style: normal;
$font-button-small-font-stretch: normal;
$font-button-small-font-style-old: Semi Bold;
$font-button-small-letter-spacing: 0.75;
$font-button-small-line-height: 26;
$font-button-small-paragraph-indent: 0;
$font-button-small-paragraph-spacing: 0;
$font-button-small-text-case: none;
$font-button-xsmall-font-size: 14;
$font-button-xsmall-text-decoration: none;
$font-button-xsmall-font-family: Inter;
$font-button-xsmall-font-weight: 600;
$font-button-xsmall-font-style: normal;
$font-button-xsmall-font-stretch: normal;
$font-button-xsmall-font-style-old: Semi Bold;
$font-button-xsmall-letter-spacing: 0.75;
$font-button-xsmall-line-height: 22;
$font-button-xsmall-paragraph-indent: 0;
$font-button-xsmall-paragraph-spacing: 0;
$font-button-xsmall-text-case: none;
$font-desktop-display-1-font-size: 80;
$font-desktop-display-1-text-decoration: none;
$font-desktop-display-1-font-family: Nunito;
$font-desktop-display-1-font-weight: 800;
$font-desktop-display-1-font-style: normal;
$font-desktop-display-1-font-stretch: normal;
$font-desktop-display-1-font-style-old: ExtraBold;
$font-desktop-display-1-letter-spacing: 0;
$font-desktop-display-1-line-height: 80;
$font-desktop-display-1-paragraph-indent: 0;
$font-desktop-display-1-paragraph-spacing: 0;
$font-desktop-display-1-text-case: none;
$font-desktop-display-2-font-size: 70;
$font-desktop-display-2-text-decoration: none;
$font-desktop-display-2-font-family: Nunito;
$font-desktop-display-2-font-weight: 800;
$font-desktop-display-2-font-style: normal;
$font-desktop-display-2-font-stretch: normal;
$font-desktop-display-2-font-style-old: ExtraBold;
$font-desktop-display-2-letter-spacing: 0;
$font-desktop-display-2-line-height: 76;
$font-desktop-display-2-paragraph-indent: 0;
$font-desktop-display-2-paragraph-spacing: 0;
$font-desktop-display-2-text-case: none;
$font-desktop-h1-font-size: 60;
$font-desktop-h1-text-decoration: none;
$font-desktop-h1-font-family: Nunito;
$font-desktop-h1-font-weight: 800;
$font-desktop-h1-font-style: normal;
$font-desktop-h1-font-stretch: normal;
$font-desktop-h1-font-style-old: ExtraBold;
$font-desktop-h1-letter-spacing: 0;
$font-desktop-h1-line-height: 80;
$font-desktop-h1-paragraph-indent: 0;
$font-desktop-h1-paragraph-spacing: 0;
$font-desktop-h1-text-case: none;
$font-desktop-h2-font-size: 50;
$font-desktop-h2-text-decoration: none;
$font-desktop-h2-font-family: Nunito;
$font-desktop-h2-font-weight: 800;
$font-desktop-h2-font-style: normal;
$font-desktop-h2-font-stretch: normal;
$font-desktop-h2-font-style-old: ExtraBold;
$font-desktop-h2-letter-spacing: 0;
$font-desktop-h2-line-height: 64;
$font-desktop-h2-paragraph-indent: 0;
$font-desktop-h2-paragraph-spacing: 0;
$font-desktop-h2-text-case: none;
$font-desktop-h3-font-size: 38;
$font-desktop-h3-text-decoration: none;
$font-desktop-h3-font-family: Nunito;
$font-desktop-h3-font-weight: 800;
$font-desktop-h3-font-style: normal;
$font-desktop-h3-font-stretch: normal;
$font-desktop-h3-font-style-old: ExtraBold;
$font-desktop-h3-letter-spacing: 0;
$font-desktop-h3-line-height: 50;
$font-desktop-h3-paragraph-indent: 0;
$font-desktop-h3-paragraph-spacing: 0;
$font-desktop-h3-text-case: none;
$font-desktop-h4-font-size: 30;
$font-desktop-h4-text-decoration: none;
$font-desktop-h4-font-family: Nunito;
$font-desktop-h4-font-weight: 800;
$font-desktop-h4-font-style: normal;
$font-desktop-h4-font-stretch: normal;
$font-desktop-h4-font-style-old: ExtraBold;
$font-desktop-h4-letter-spacing: 0;
$font-desktop-h4-line-height: 47;
$font-desktop-h4-paragraph-indent: 0;
$font-desktop-h4-paragraph-spacing: 0;
$font-desktop-h4-text-case: none;
$font-desktop-h5-font-size: 24;
$font-desktop-h5-text-decoration: none;
$font-desktop-h5-font-family: Nunito;
$font-desktop-h5-font-weight: 800;
$font-desktop-h5-font-style: normal;
$font-desktop-h5-font-stretch: normal;
$font-desktop-h5-font-style-old: ExtraBold;
$font-desktop-h5-letter-spacing: 0;
$font-desktop-h5-line-height: 36;
$font-desktop-h5-paragraph-indent: 0;
$font-desktop-h5-paragraph-spacing: 0;
$font-desktop-h5-text-case: none;
$font-desktop-h6-font-size: 18;
$font-desktop-h6-text-decoration: none;
$font-desktop-h6-font-family: Nunito;
$font-desktop-h6-font-weight: 800;
$font-desktop-h6-font-style: normal;
$font-desktop-h6-font-stretch: normal;
$font-desktop-h6-font-style-old: ExtraBold;
$font-desktop-h6-letter-spacing: 0;
$font-desktop-h6-line-height: 24;
$font-desktop-h6-paragraph-indent: 0;
$font-desktop-h6-paragraph-spacing: 0;
$font-desktop-h6-text-case: none;
$font-mobile-display-1-font-size: 48;
$font-mobile-display-1-text-decoration: none;
$font-mobile-display-1-font-family: Inter;
$font-mobile-display-1-font-weight: 800;
$font-mobile-display-1-font-style: normal;
$font-mobile-display-1-font-stretch: normal;
$font-mobile-display-1-font-style-old: Extra Bold;
$font-mobile-display-1-letter-spacing: 1;
$font-mobile-display-1-line-height: 56;
$font-mobile-display-1-paragraph-indent: 0;
$font-mobile-display-1-paragraph-spacing: 0;
$font-mobile-display-1-text-case: none;
$font-mobile-display-2-font-size: 40;
$font-mobile-display-2-text-decoration: none;
$font-mobile-display-2-font-family: Inter;
$font-mobile-display-2-font-weight: 800;
$font-mobile-display-2-font-style: normal;
$font-mobile-display-2-font-stretch: normal;
$font-mobile-display-2-font-style-old: Extra Bold;
$font-mobile-display-2-letter-spacing: 1;
$font-mobile-display-2-line-height: 48;
$font-mobile-display-2-paragraph-indent: 0;
$font-mobile-display-2-paragraph-spacing: 0;
$font-mobile-display-2-text-case: none;
$font-mobile-h1-font-size: 32;
$font-mobile-h1-text-decoration: none;
$font-mobile-h1-font-family: Nunito;
$font-mobile-h1-font-weight: 800;
$font-mobile-h1-font-style: normal;
$font-mobile-h1-font-stretch: normal;
$font-mobile-h1-font-style-old: ExtraBold;
$font-mobile-h1-letter-spacing: 0;
$font-mobile-h1-line-height: 40;
$font-mobile-h1-paragraph-indent: 0;
$font-mobile-h1-paragraph-spacing: 0;
$font-mobile-h1-text-case: none;
$font-mobile-h2-font-size: 28;
$font-mobile-h2-text-decoration: none;
$font-mobile-h2-font-family: Inter;
$font-mobile-h2-font-weight: 800;
$font-mobile-h2-font-style: normal;
$font-mobile-h2-font-stretch: normal;
$font-mobile-h2-font-style-old: Extra Bold;
$font-mobile-h2-letter-spacing: 0;
$font-mobile-h2-line-height: 36;
$font-mobile-h2-paragraph-indent: 0;
$font-mobile-h2-paragraph-spacing: 0;
$font-mobile-h2-text-case: none;
$font-mobile-h3-font-size: 24;
$font-mobile-h3-text-decoration: none;
$font-mobile-h3-font-family: Inter;
$font-mobile-h3-font-weight: 800;
$font-mobile-h3-font-style: normal;
$font-mobile-h3-font-stretch: normal;
$font-mobile-h3-font-style-old: Extra Bold;
$font-mobile-h3-letter-spacing: 0;
$font-mobile-h3-line-height: 32;
$font-mobile-h3-paragraph-indent: 0;
$font-mobile-h3-paragraph-spacing: 0;
$font-mobile-h3-text-case: none;
$font-mobile-h4-font-size: 20;
$font-mobile-h4-text-decoration: none;
$font-mobile-h4-font-family: Inter;
$font-mobile-h4-font-weight: 800;
$font-mobile-h4-font-style: normal;
$font-mobile-h4-font-stretch: normal;
$font-mobile-h4-font-style-old: Extra Bold;
$font-mobile-h4-letter-spacing: 0;
$font-mobile-h4-line-height: 28;
$font-mobile-h4-paragraph-indent: 0;
$font-mobile-h4-paragraph-spacing: 0;
$font-mobile-h4-text-case: none;
$font-mobile-h5-font-size: 18;
$font-mobile-h5-text-decoration: none;
$font-mobile-h5-font-family: Inter;
$font-mobile-h5-font-weight: 800;
$font-mobile-h5-font-style: normal;
$font-mobile-h5-font-stretch: normal;
$font-mobile-h5-font-style-old: Extra Bold;
$font-mobile-h5-letter-spacing: 0;
$font-mobile-h5-line-height: 24;
$font-mobile-h5-paragraph-indent: 0;
$font-mobile-h5-paragraph-spacing: 0;
$font-mobile-h5-text-case: none;
$font-mobile-h6-font-size: 16;
$font-mobile-h6-text-decoration: none;
$font-mobile-h6-font-family: Inter;
$font-mobile-h6-font-weight: 800;
$font-mobile-h6-font-style: normal;
$font-mobile-h6-font-stretch: normal;
$font-mobile-h6-font-style-old: Extra Bold;
$font-mobile-h6-letter-spacing: 0;
$font-mobile-h6-line-height: 22;
$font-mobile-h6-paragraph-indent: 0;
$font-mobile-h6-paragraph-spacing: 0;
$font-mobile-h6-text-case: none;
$effect-elevation-dark-01-0-type: dropShadow;
$effect-elevation-dark-01-0-radius: 2;
$effect-elevation-dark-01-0-color: rgba(0, 0, 0, 0.32);
$effect-elevation-dark-01-0-offset-x: 0;
$effect-elevation-dark-01-0-offset-y: 1;
$effect-elevation-dark-01-0-spread: 0;
$effect-elevation-dark-01-1-type: dropShadow;
$effect-elevation-dark-01-1-radius: 1;
$effect-elevation-dark-01-1-color: rgba(40, 41, 61, 0.08);
$effect-elevation-dark-01-1-offset-x: 0;
$effect-elevation-dark-01-1-offset-y: 0;
$effect-elevation-dark-01-1-spread: 0;
$effect-elevation-dark-02-0-type: dropShadow;
$effect-elevation-dark-02-0-radius: 4;
$effect-elevation-dark-02-0-color: rgba(0, 0, 0, 0.32);
$effect-elevation-dark-02-0-offset-x: 0;
$effect-elevation-dark-02-0-offset-y: 2;
$effect-elevation-dark-02-0-spread: 0;
$effect-elevation-dark-02-1-type: dropShadow;
$effect-elevation-dark-02-1-radius: 1;
$effect-elevation-dark-02-1-color: rgba(0, 0, 0, 0.04);
$effect-elevation-dark-02-1-offset-x: 0;
$effect-elevation-dark-02-1-offset-y: 0;
$effect-elevation-dark-02-1-spread: 0;
$effect-elevation-dark-03-0-type: dropShadow;
$effect-elevation-dark-03-0-radius: 8;
$effect-elevation-dark-03-0-color: rgba(0, 0, 0, 0.32);
$effect-elevation-dark-03-0-offset-x: 0;
$effect-elevation-dark-03-0-offset-y: 4;
$effect-elevation-dark-03-0-spread: 0;
$effect-elevation-dark-03-1-type: dropShadow;
$effect-elevation-dark-03-1-radius: 2;
$effect-elevation-dark-03-1-color: rgba(0, 0, 0, 0.04);
$effect-elevation-dark-03-1-offset-x: 0;
$effect-elevation-dark-03-1-offset-y: 0;
$effect-elevation-dark-03-1-spread: 0;
$effect-elevation-dark-04-0-type: dropShadow;
$effect-elevation-dark-04-0-radius: 16;
$effect-elevation-dark-04-0-color: rgba(0, 0, 0, 0.32);
$effect-elevation-dark-04-0-offset-x: 0;
$effect-elevation-dark-04-0-offset-y: 8;
$effect-elevation-dark-04-0-spread: 0;
$effect-elevation-dark-04-1-type: dropShadow;
$effect-elevation-dark-04-1-radius: 4;
$effect-elevation-dark-04-1-color: rgba(0, 0, 0, 0.04);
$effect-elevation-dark-04-1-offset-x: 0;
$effect-elevation-dark-04-1-offset-y: 2;
$effect-elevation-dark-04-1-spread: 0;
$effect-elevation-dark-05-0-type: dropShadow;
$effect-elevation-dark-05-0-radius: 24;
$effect-elevation-dark-05-0-color: rgba(0, 0, 0, 0.32);
$effect-elevation-dark-05-0-offset-x: 0;
$effect-elevation-dark-05-0-offset-y: 16;
$effect-elevation-dark-05-0-spread: 0;
$effect-elevation-dark-05-1-type: dropShadow;
$effect-elevation-dark-05-1-radius: 8;
$effect-elevation-dark-05-1-color: rgba(0, 0, 0, 0.04);
$effect-elevation-dark-05-1-offset-x: 0;
$effect-elevation-dark-05-1-offset-y: 2;
$effect-elevation-dark-05-1-spread: 0;
$effect-elevation-dark-06-0-type: dropShadow;
$effect-elevation-dark-06-0-radius: 32;
$effect-elevation-dark-06-0-color: rgba(0, 0, 0, 0.32);
$effect-elevation-dark-06-0-offset-x: 0;
$effect-elevation-dark-06-0-offset-y: 20;
$effect-elevation-dark-06-0-spread: 0;
$effect-elevation-dark-06-1-type: dropShadow;
$effect-elevation-dark-06-1-radius: 8;
$effect-elevation-dark-06-1-color: rgba(0, 0, 0, 0.08);
$effect-elevation-dark-06-1-offset-x: 0;
$effect-elevation-dark-06-1-offset-y: 2;
$effect-elevation-dark-06-1-spread: 0;
$effect-elevation-light-01-0-type: dropShadow;
$effect-elevation-light-01-0-radius: 2;
$effect-elevation-light-01-0-color: rgba(96, 97, 112, 0.16);
$effect-elevation-light-01-0-offset-x: 0;
$effect-elevation-light-01-0-offset-y: 0.5;
$effect-elevation-light-01-0-spread: 0;
$effect-elevation-light-01-1-type: dropShadow;
$effect-elevation-light-01-1-radius: 1;
$effect-elevation-light-01-1-color: rgba(40, 41, 61, 0.08);
$effect-elevation-light-01-1-offset-x: 0;
$effect-elevation-light-01-1-offset-y: 0;
$effect-elevation-light-01-1-spread: 0;
$effect-elevation-light-02-0-type: dropShadow;
$effect-elevation-light-02-0-radius: 4;
$effect-elevation-light-02-0-color: rgba(96, 97, 112, 0.16);
$effect-elevation-light-02-0-offset-x: 0;
$effect-elevation-light-02-0-offset-y: 2;
$effect-elevation-light-02-0-spread: 0;
$effect-elevation-light-02-1-type: dropShadow;
$effect-elevation-light-02-1-radius: 1;
$effect-elevation-light-02-1-color: rgba(40, 41, 61, 0.04);
$effect-elevation-light-02-1-offset-x: 0;
$effect-elevation-light-02-1-offset-y: 0;
$effect-elevation-light-02-1-spread: 0;
$effect-elevation-light-03-0-type: dropShadow;
$effect-elevation-light-03-0-radius: 8;
$effect-elevation-light-03-0-color: rgba(96, 97, 112, 0.16);
$effect-elevation-light-03-0-offset-x: 0;
$effect-elevation-light-03-0-offset-y: 4;
$effect-elevation-light-03-0-spread: 0;
$effect-elevation-light-03-1-type: dropShadow;
$effect-elevation-light-03-1-radius: 2;
$effect-elevation-light-03-1-color: rgba(40, 41, 61, 0.04);
$effect-elevation-light-03-1-offset-x: 0;
$effect-elevation-light-03-1-offset-y: 0;
$effect-elevation-light-03-1-spread: 0;
$effect-elevation-light-04-0-type: dropShadow;
$effect-elevation-light-04-0-radius: 16;
$effect-elevation-light-04-0-color: rgba(96, 97, 112, 0.16);
$effect-elevation-light-04-0-offset-x: 0;
$effect-elevation-light-04-0-offset-y: 8;
$effect-elevation-light-04-0-spread: 0;
$effect-elevation-light-04-1-type: dropShadow;
$effect-elevation-light-04-1-radius: 4;
$effect-elevation-light-04-1-color: rgba(40, 41, 61, 0.04);
$effect-elevation-light-04-1-offset-x: 0;
$effect-elevation-light-04-1-offset-y: 2;
$effect-elevation-light-04-1-spread: 0;
$effect-elevation-light-05-0-type: dropShadow;
$effect-elevation-light-05-0-radius: 24;
$effect-elevation-light-05-0-color: rgba(96, 97, 112, 0.16);
$effect-elevation-light-05-0-offset-x: 0;
$effect-elevation-light-05-0-offset-y: 16;
$effect-elevation-light-05-0-spread: 0;
$effect-elevation-light-05-1-type: dropShadow;
$effect-elevation-light-05-1-radius: 8;
$effect-elevation-light-05-1-color: rgba(40, 41, 61, 0.04);
$effect-elevation-light-05-1-offset-x: 0;
$effect-elevation-light-05-1-offset-y: 2;
$effect-elevation-light-05-1-spread: 0;
$effect-elevation-light-06-0-type: dropShadow;
$effect-elevation-light-06-0-radius: 32;
$effect-elevation-light-06-0-color: rgba(96, 97, 112, 0.24);
$effect-elevation-light-06-0-offset-x: 0;
$effect-elevation-light-06-0-offset-y: 20;
$effect-elevation-light-06-0-spread: 0;
$effect-elevation-light-06-1-type: dropShadow;
$effect-elevation-light-06-1-radius: 8;
$effect-elevation-light-06-1-color: rgba(40, 41, 61, 0.08);
$effect-elevation-light-06-1-offset-x: 0;
$effect-elevation-light-06-1-offset-y: 2;
$effect-elevation-light-06-1-spread: 0;
.rsce_stage {
  background-image: url(../img/bg_stage.svg);
  background-size: 180% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  min-height: 296px;
  padding-top: 29px;
  padding-bottom: 120px;
  position: relative;

  .stage__image {
    transform: rotate(1deg);
    height: 140px;
    width: 140px;
    position: absolute;
    left: 50%;
    bottom: -50px;
    margin-left: -70px;

    img {
      border-radius: 31% 69% 55% 45% / 28% 52% 48% 72%;
    }
  }
  .stage__image--2 {
    display: none;
  }

  .h1 {
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 576px) {
  .rsce_stage {
    .stage__image {
      margin-left: -210px;
    }
    .stage__image::after {
      background: transparent url(../img/icon_form_1.svg) no-repeat center;
      background-size: contain;
      content: "";
      display: block;
      height: 80px;
      width: 80px;
      position: absolute;
      bottom: 0;
      left: 42px;
      z-index: -1;
      transform: rotate(-22deg);
    }
    .stage__image--2 {
      display: block;
      transform: rotate(0deg);
      margin-left: 60px;
      
      img {
        border-radius: 51% 49% 49% 51% / 43% 43% 57% 57%;
      }
    }
    .stage__image--2::after {
      background: transparent url(../img/icon_form_4.svg) no-repeat center;
      background-size: contain;
      left: -14px;
      transform: rotate(-3deg);
      z-index: 1;
    }
    .stage__image--2::before {
      background: transparent url(../img/icon_form_5.svg) no-repeat center;
      background-size: contain;
      content: "";
      display: block;
      height: 60px;
      width: 60px;
      position: absolute;
      bottom: 30px;
      left: 82px;
      transform: rotate(-3deg);
      z-index: -1;
    }
  }
}
@media only screen and (min-width: 992px) {
  .rsce_stage {
    min-height: 400px;

    .stage__image {
      bottom: -60px;
      height: 200px;
      margin-left: -280px;
      width: 200px;
    }
    .stage__image::after {
      bottom: -10px;
      height: 120px;
      left: 63px;
      width: 120px;
  } 
    .stage__image--2 {
      bottom: -63px;
      margin-left: 100px;
    }
    .stage__image--2::after {
      left: -37px;
    }
    .stage__image--2::before {
      bottom: 20px;
      height: 90px;
      left: 122px;
      width: 90px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .rsce_stage {
    background-size: 102% auto;
    min-height: 736px;
    padding-top: 9px;

    .stage__image {
      bottom: 93px;
      height: 340px;
      margin-left: -561px;
      width: 340px;
    }
    .stage__image::after {
      bottom: -40px;
      height: 200px;
      left: 123px;
      width: 200px;
    }
    .stage__image--2 {
      bottom: 63px;
      margin-left: 220px;
    }
    .stage__image--2::after {
      left: -84px;
    }
    .stage__image--2::before {
      bottom: 40px;
      height: 150px;
      left: 212px;
      width: 150px;
    }
    .h1 {
      font-size: 70px;
      line-height: 76px;
    }
  }
}
@media only screen and (min-width: 1400px) {
  .rsce_stage {
    .stage__image {
      margin-left: -641px;
    }
    .stage__image--2 {
      margin-left: 260px;
    }
  }
}
@media only screen and (min-width: 2200px) {
  .rsce_stage {
    background-size: 108% auto;
  }
}
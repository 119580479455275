.textfield {
  background-color: $color-black-01;
  display: inline-block;
  height: 48px;
  padding-top: to-rem(20px);
  border: 1px solid $color-black-60;
  border-radius: 4px;

  &__input {
    background: none;
    border: none;
    box-sizing: border-box;
    color: $color-black-90;
    display: block;
    font-family: $font-medium-font-family, Arial, sans-serif;
    font-size: to-rem($font-medium-font-size);
    padding: 0 0.75rem 0.25rem;
    width: 100%;

    &:invalid {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }

  }

  &__label {
    display: inline-block;
    font-family: $font-medium-font-family, Arial, sans-serif;
    font-size: 0;
    pointer-events: none;
    position: relative;
    width: 100%;

    &::before {
      content: attr(data-content);
      position: relative;
      transition: all 0.2s ease;
      will-change: font-size, top;
    }
    &::after {
      // focus activate effect
      transition: all 0.2s ease;
      will-change: width, left;
    }
  }
}


.textfield .textfield__label::before, .textfield .textfield__input[required]:focus ~ .textfield__label::before {
  // focused label
  font-size: to-rem($font-xsmall-font-size);
  top: -3.25rem;
  left: 0.75rem;
}

.textfield .textfield__input:focus ~ .textfield__label::after {
  left: 0%;
  width: 100%;
}

.textfield .textfield__input:invalid ~ .textfield__label::before {
  // label initial place
  font-size: 1rem;
  left: 0.75rem;
  top: -2.25rem;
}
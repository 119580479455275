.rsce_team {
  margin: 0 auto;
  max-width: 728px;
  text-align: center;

  .team__copy {
    font-size: $font-large-font-size + px;
    line-height: $font-large-line-height + px;
  }

}
.rsce_teamlist {

  .team__item {
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;

    img {
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }

    // border-radius: 31% 69% 55% 45% / 28% 52% 48% 72%;
    // border-radius: 63% 37% 36% 64% / 46% 46% 54% 54%;
    // border-radius: 52% 48% 52% 48% / 42% 44% 56% 58%;

    // border-radius: 31% 69% 55% 45% / 28% 52% 48% 72%;
    // border-radius: 52% 48% 52% 48% / 42% 44% 56% 58%;
    // border-radius: 69% 31% 43% 57% / 54% 44% 56% 46%;

    span {
      display: block;
      font-size: $font-large-font-size + px;
      line-height: $font-large-line-height + px;
    }
  }
  .team__item:nth-child(6n+1) img {
    border-radius: 31% 69% 55% 45% / 28% 52% 48% 72%;
  }
  .team__item:nth-child(6n+2) img {
    border-radius: 63% 37% 36% 64% / 46% 46% 54% 54%;
  }
  .team__item:nth-child(6n+3) img {
    border-radius: 52% 48% 52% 48% / 42% 44% 56% 58%;
  }
  .team__item:nth-child(6n+4) img {
    border-radius: 31% 69% 55% 45% / 28% 52% 48% 72%;
  }
  .team__item:nth-child(6n+5) img {
    border-radius: 52% 48% 52% 48% / 42% 44% 56% 58%;
  }
  .team__item:nth-child(6n+6) img {
    border-radius: 69% 31% 43% 57% / 54% 44% 56% 46%;
  }
  // .team__item:nth-child(1) img {
  // }
  // .team__item:nth-child(2) img {
  // }
  // .team__item:nth-child(3) img {
  // }
  // .team__item:nth-child(4) img {
  // }
  // .team__item:nth-child(5) img {
  // }
  // .team__item:nth-child(6) img {
  // }
}

@media only screen and (min-width: 768px) {
  .rsce_teamlist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 20px;

    .team__item {
      background-size: 260px;

      .name {
        font-size: $font-desktop-h4-font-size + px;
        line-height: $font-desktop-h4-line-height + px;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .rsce_teamlist {
    grid-template-columns: repeat(3, 1fr);
  }
}
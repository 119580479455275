footer {
  background-color: $footer-background-color;
  background-image: url(../img/bg_footer.svg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: text-color-checker($footer-text-color, $footer-background-color);
  padding: 6rem 0 4rem;
}
@media only screen and (min-width: 768px) {
  footer {
    padding-top: 7rem;
  }
}
@media only screen and (min-width: 992px) {
  footer {
    padding-top: 9rem;
  }
}
@media only screen and (min-width: 1200px) {
  footer {
    padding-top: 11rem;
  }
}


/* Footernavigation */
.footer_link {
  background: transparent url(../img/bg_link.svg) no-repeat left 9px;
  background-size: 7px 14px;
  display: inline-block;
  font-size: to-rem(18);
  line-height: to-rem(32);
  margin-right: 31px;
  margin-bottom: 1.5rem;
  padding-left: 20px;
}
.footer_link:hover {
  color: $color-primary;
}
@media only screen and (min-width: 768px) {
  .footer_link {
    margin-left: 31px;
    margin-right: 0;
  }
}
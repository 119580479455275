/* -------------------------------------------------------------
//  List
// -----------------------------------------------------------*/
.list {
  margin: 0;
  margin-bottom: to-rem($font-medium-line-height);
  padding: 0;

  .list__item {
    font-size: to-rem($font-medium-font-size);
    line-height: to-rem($font-medium-line-height);
    margin: 0;
    margin-left: to-rem($font-medium-font-size);
    margin-bottom: to-rem($font-medium-line-height);
    padding: 0;
  }
}

.ce_text {
  ol {
    counter-reset: section;
    margin-top: 2rem;

    li {
      font-family: Nunito, Helvetica, Arial;
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 20px;
      min-height: 50px;
      padding-left: 75px;
      padding-top: 10px;
      padding-right: 50px;
      position: relative;
    }
    li::before {
      background-color: $color-primary-light;
      border-radius: 50%;
      counter-increment: section;
      content: counters(section,".") ".";
      display: flex;
      font-family: Nunito, Helvetica, Arial;
      font-size: 18px;
      font-weight: 800;
      height: 50px;
      justify-content: center;
      left: 0;
      padding-top: 12px;
      position: absolute;
      top: 0;
      width: 50px;
    }
  }
  ul {
    margin-bottom: to-rem($font-large-line-height);
    li {
      font-size: to-rem($font-large-font-size);
      line-height: to-rem($font-large-line-height);
      list-style-type: disc;
      margin-left: 1rem;
      max-width: 825px;
    }
  }
}
@media only screen and (min-width: 992px) {
  .ce_text {
    ol {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      margin-top: 4rem;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .ce_text {
    ol {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 4.7rem;

      li {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 26px;
        padding-top: 7px;
      }
      li::before {
        font-size: 24px;
        line-height: 36px;
        padding-top: 8px;
      }
    }
  }
}
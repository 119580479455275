/* -------------------------------------------------------------
//  Fonts
// -----------------------------------------------------------*/
/* Lato */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/lato-v23-latin-regular.woff2') format('woff2'),
       url('../fonts/lato-v23-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/lato-v23-latin-700.woff2') format('woff2'),
       url('../fonts/lato-v23-latin-700.woff') format('woff');
}
/* Nunito */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../fonts/nunito-v24-latin-800.woff2') format('woff2'),
       url('../fonts/nunito-v24-latin-800.woff') format('woff');
}
/* Icons */
@font-face {
  font-family: "pewi";
  src:url("../fonts/pewi.eot");
  src:url("../fonts/pewi.eot?#iefix") format("embedded-opentype"),
    url("../fonts/pewi.woff") format("woff"),
    url("../fonts/pewi.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "pewi" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "pewi" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-vector-1:before {
  content: "\61";
}
.icon-vector-2:before {
  content: "\62";
}
.icon-vector-4:before {
  content: "\63";
}
.icon-vector-5:before {
  content: "\64";
}
.icon-vector-6:before {
  content: "\65";
}
.icon-vector-7:before {
  content: "\66";
}
.icon-vector-8:before {
  content: "\67";
}
.icon-vector-9:before {
  content: "\68";
}
.icon-vector-3:before {
  content: "\69";
}
header {
  background-color: $header-background-color;
  box-shadow: 0 0 0 rgb(0 0 0 / 0%);
  left: 0;
  position: fixed;
  top: 0;
  transition: box-shadow 0.1s ease-in;
  width: 100%;
  z-index: 2;
}
header.sticky {
  box-shadow: 0 0 8px rgb(0 0 0 / 30%);
}
header.zindex {
  
}
@media only screen and (min-width: 992px) {
  header {
    background: transparent url(../img/bg_navbar.svg) no-repeat center bottom;
    background-size: cover;
    box-shadow: 0 0 0 rgb(0 0 0 / 0%) !important;
    left: unset;
    min-height: 170px;
    position: relative;
    top: unset;
  }
  body.stage header {
    background: $header-background-color;
  }
}
.rsce_faq {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 45px;
  grid-row-gap: 45px;
}
.faq__item {
  border-radius: 60px;
  border: none !important;
  background-color: $color-bg-blue-bright;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 350px;
  padding: 44px;
  transition: transform 0.15s ease-in-out;
}
.faq__item:first-child {
  background: transparent;
  border-radius: 0;
  display: block;
  padding: 0;
}
.faq__item:hover {
  // animation: shake 0.5s;
  // animation-iteration-count: 1;
  background-color: $color-green-normal;
  color: $color-black-80;
  transform: rotate(-5.86deg);
}
.faq__item:first-child:hover {
  animation: unset;
  background-color: $color-white;
  transform: rotate(0deg);
};

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@media only screen and (min-width: 768px) {
  .rsce_faq {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1200px) {
  .rsce_faq {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* -------------------------------------------------------------
//  Accordion
// -----------------------------------------------------------*/
.accordion {
  margin: 0 0 to-rem($accordion-margin-bottom) 0;
  padding: 0;

  &.focus {
    border-color: $accordion-border-color;
  }

  & > * + * {
    border-top: 1px solid $accordion-border-color;
  }

  h3 {
    margin: 0;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  // accordion header
  &__header {
    background: none;
    border-style: none;
    color: $accordion-font-color;
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: to-rem($font-medium-line-height);
    margin: 0;
    padding: 1em 4em 1em 1.5em;
    position: relative;
    text-align: left;
    width: 100%;
    outline: none;

    &:hover {
      background: $accordion-background-hover;
    }
    
    &[aria-expanded='true'] .accordion-icon {
      speak: none;
    }
    &[aria-expanded='true'] .accordion-icon::before {
      display: none;
    }
  }

  &__title {
    pointer-events: none;
    padding: 0.25em;
    outline: none;
  }

  //accordion panel
  &__panel {
    margin: 0;
    padding: 1em 1.5em;

    &[hidden] {
      display: none;
    }

    ul li {
      margin-bottom: 1em;
      padding-left: 30px;
    }
  }

  //accordion icons
  &-icon {
    height: to-rem($font-medium-font-size);
    pointer-events: none;
    position: absolute;
    right: to-rem($font-medium-font-size);
    top: to-rem($font-medium-font-size);
    width: to-rem($font-medium-font-size);
    speak: none;

    &::before,
    &::after {
    background-color: $color-primary;
    content: '';
    display: block;
    height: 2px;
    width: to-rem($font-medium-font-size);
    position: absolute;
    top: 12px;
    left: 0px;
    }
    &::before {
      transform: rotate(90deg);
    }
  }
}

p {
  font-size: to-rem($font-large-font-size);
  line-height: to-rem($font-large-line-height);
  margin-bottom: to-rem($font-large-line-height);
  max-width: 825px;
}

p.lead {
  font-size: to-rem($font-xlarge-font-size);
  line-height: to-rem($font-xlarge-line-height);
  margin-bottom: to-rem($font-xlarge-line-height);
}

p.small,
small {
  font-size: to-rem($font-small-font-size);
  line-height: to-rem($font-small-line-height);
  margin-bottom: to-rem($font-small-line-height);
}

strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: 0; // resets margin of first paragraph
}

hr {
  border-top: 1px solid $color-black-50;
  margin-bottom: 27px;
}
.footer p {
  margin-bottom: 19px;
}
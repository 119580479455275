/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/

.skip-nav-link {
  background-color: #fff;
  border: 2px solid #4d7cae;
  border-radius: 3px;
  color: #4d7cae;
  left: 10px;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 10px;
  transform: translateX(-120%);
  transition: transform 250ms ease-in;
}
.skip-nav-link:focus-visible {
  transform: translateX(0%);
}

.navbar {
  background-color: $navbar-background-color;
}

.navbarwrapper {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
}

.navmain {
  left: 0;
  opacity: 0;
  padding-top: 90px;
  padding-bottom: 96px;
  position: absolute;
  top: -999em;
  width: 100%;
}
.navmain.is-open {
  opacity: 1;
  top: 0;
}

.navmain::before {
  background: transparent url(../img/bg_menu.svg) no-repeat left bottom;
  background-size: 70% auto;
  bottom: -10vw;
  content: "";
  display: block;
  height: 203vw;
  position: absolute;
  right: -90%;
  width: 203vw;
  transform-origin: right top;
  transform: scale(0.01);
}

.navmain.is-open::before {
  animation: bounce 0.5s 1 0.2s forwards cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

@keyframes bounce {
  0% {
    transform: scale(0.01);
    opacity: 1;
  }

  95% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.navlist__item {
  text-align: right;
  opacity: 0;
}

.navmain.is-open .navlist__item:first-of-type {
  animation: fadein .3s 1 .4s normal forwards;
}

.navmain.is-open .navlist__item:nth-of-type(2) {
  animation: fadein .3s 1 .5s normal forwards;
}

.navmain.is-open .navlist__item:nth-of-type(3) {
  animation: fadein .3s 1 .6s normal forwards;
}

.navmain.is-open .navlist__item:nth-of-type(4) {
  animation: fadein .3s 1 .7s normal forwards;
}

.navmain.is-open .navlist__item:nth-of-type(5) {
  animation: fadein .3s 1 .8s normal forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navlist__link {
  background-image: linear-gradient( to right, $color-yellow-dark, $color-yellow-dark 50%, $color-white 50%);
  background-size: 200% 100%;
  background-position: -100%;
  color: $color-white;
  font-size: to-rem(28);
  line-height: to-rem(56);
  font-weight: 800;
  font-family: 'Nunito';
  text-decoration: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.15s ease-in-out;
}

.navlist__link:hover {
  color: $color-yellow-dark;
  background-position: 0%;
}


@media only screen and (min-width: 992px) {
  .navbarwrapper {
    height: 170px;
    max-width: 1440px;
  }
  .navbar {
    position: fixed;
    width: 100%;
  }
  .navmain {
    padding-top: 180px;
  }
  .navmain::before {
    background-size: 100% auto;
    right: 0;
    width: 100%;
    z-index: -1;
  }
  .navlist__link {
    font-size: to-rem(60);
    line-height: to-rem(85);
  }
}


// Burger Button
.navbutton {
  display: block;
  height: 36px;
  cursor: pointer;
  position: relative;
  width: 36px;
}
.line {
  border-radius: 2px;
  display: block;
  height: 7px;
  position: absolute;
  width: 30px;
  transition: left 0.15s ease-in-out, opacity 0.15s ease-in-out, top 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.line--top {
  background-color: $color-green-dark;
  left: 5.27px;
  top: 3.2px;
  transform: rotate(1.92deg);
}
.line--middle {
  background-color: $color-yellow-dark;
  left: 1px;
  top: 13.13px;
  transform: rotate(-4.66deg);
  z-index: 1;
}
.line--bottom {
  background-color: $color-orange-dark;
  left: 4.52px;
  top: 23.61px;
  transform: rotate(4.3deg);
}

.navbutton:hover {
  .line--top {
    left: 3px;
    top: 3px;
    transform: rotate(0deg);
  }
  .line--middle {
    left: 3px;
    top: 13px;
    transform: rotate(0deg);
    z-index: 1;
  }
  .line--bottom {
    left: 3px;
    top: 23px;
    transform: rotate(0deg);
  }
}

.navbutton {

  &.active {
    .line--top {
      opacity: 0;
    }
    .line--middle {
      left: 4px;
      top: 14px;
      transform: rotate(-45deg);
    }
    .line--bottom {
      left: 4px;
      top: 14px;
      transform: rotate(45deg);
    }
  }
}

// Servicenav
.mod_customnav a:hover,
.mod_customnav a:focus {
  color: $color-primary;
}
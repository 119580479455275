/* -------------------------------------------------------------
//  Table
// -----------------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .ce_text {
    table {
      width: 100% !important;
    }
    tr {
      display: flex;
      flex-direction: column;
      align-items: center;

      td {
        width: 100% !important;
      }
    }
  }
}
.rsce_units {

  .unit__copy {
    font-size: $font-large-font-size + px;
    line-height: $font-large-line-height + px;
  }
}
.rsce_unitlist {
  .unit__item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 220px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 25px;
    width: 300px;
    transition: background-size 0.15s ease-in-out;
    
    .name {
      color: $color-black-80;
      display: block;
      font-family: $font-mobile-h1-font-family, Helvetica, Arial;
      font-size: $font-mobile-h3-font-size + px;
      font-weight: $font-mobile-h3-font-weight;
      line-height: $font-mobile-h3-line-height + px;
      padding-top: 25px;
    }
  }
  .unit__linkwrapper:nth-child(1) .unit__item {
    background-image: url(../img/icon_form_4.svg);
  }
  .unit__linkwrapper:nth-child(2) .unit__item {
    background-image: url(../img/icon_form_5.svg);
    background-size: 220px;
  }
  .unit__linkwrapper:nth-child(3) .unit__item {
    background-image: url(../img/icon_form_6.svg);
  }
  .unit__linkwrapper:nth-child(4) .unit__item {
    background-image: url(../img/icon_form_7.svg);
  }
  .unit__linkwrapper:nth-child(5) .unit__item {
    background-image: url(../img/icon_form_8.svg);
  }
  .unit__item:hover {
    background-size: 300px;
  }
  .unit__linkwrapper:nth-child(2):hover .unit__item {
    background-size: 270px;
  }
}
.unit__linkwrapper,
.unit__linkwrapper:hover,
.unit__linkwrapper:focus {
  border: none;
}
.unit__link {
  border: none;
  background-image: url(../img/btn_link.svg);
  background-repeat: no-repeat;
  background-position: left 5px;
  padding-left: 28px;
  opacity: 0;
}
.unit__link:hover {
  border: none;
}
.unit__linkwrapper:hover .unit__link {
  color: $color-black;
  opacity: 1;
}
@media only screen and (min-width: 992px) {
  .rsce_unitlist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    .unit__item {
      background-size: 260px;
      .name {
        font-size: $font-desktop-h3-font-size + px;
        line-height: $font-desktop-h3-line-height + px;
      }
    }
  }
}
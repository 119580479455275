/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
a, a:visited {

}
a:focus-visible {
  outline: 3px solid $color-primary-light;
}

.ce_text a,
.ce_text a:visited,
main a, a:visited {
  border-bottom: 1px solid $color-black;
}
.ce_text a:hover,
main a:hover {
  border-bottom: 1px solid $color-primary-dark;
  color: $color-primary;
}

.link {
  background: transparent url(../img/bg_link.svg) no-repeat left 9px;
  background-size: 7px 14px;
  border: none;
  display: inline-block;
  font-size: to-rem(18);
  font-weight: 500;
  line-height: to-rem(32);
  margin-right: 31px;
  margin-bottom: 1.5rem;
  padding-left: 20px;
}
.link:hover {
  border: none;
  color: $color-primary;
}
.no_link_decoration {
  a, a:hover, a:visited {
    border: none;
    text-decoration: none !important;
  }
}
.rsce_data_facts {

  .fact__item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 220px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: background-size 0.15s ease-in-out;

    &.fact__item--1 {
      background-image: url(../img/icon_form_1.svg);
    }
    &.fact__item--2 {
      background-image: url(../img/icon_form_2.svg);
    }
    &.fact__item--3 {
      background-image: url(../img/icon_form_3.svg);
      background-position: calc(50% - -11px) 50%;
    }

    .number {
      color: $color-black-80;
      display: block;
      font-family: $font-mobile-h1-font-family, Helvetica, Arial;
      font-size: $font-mobile-display-2-font-size + px;
      line-height: $font-mobile-display-2-line-height + px;
    }
    .facts {
      color: $color-black-80;
      display: block;
      font-family: $font-mobile-h1-font-family, Helvetica, Arial;
      font-size: $font-mobile-h5-font-size + px;
      line-height: $font-mobile-h5-line-height + px;
    }

    &:hover {
      background-size: 280px;
    }
  }
}
@media only screen and (min-width: 992px) {
  .rsce_data_facts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    .fact__item {

      .number {
        font-size: $font-desktop-display-2-font-size + px;
        line-height: $font-desktop-display-2-line-height + px;
      }
      .facts {
        font-size: $font-desktop-h5-font-size + px;
        line-height: $font-desktop-h5-line-height + px;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .rsce_data_facts {
    .fact__item {
      background-size: 300px;
      height: 340px;
      &:hover {
        background-size: 360px;
      }
    }
  }
}
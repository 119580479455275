/* -------------------------------------------------------------
//  Slider
// -----------------------------------------------------------*/
.swiper {
  padding-bottom: 80px;
  width: 100%;
}
.swiper-wrapper {
  height: unset;
  width: unset;
}
.swiper-slide {
  border-radius: 60px;
  font-size: 18px;
  overflow: hidden;
  text-align: center;
}

.swiper-slide img,
.swiper-slide picture {
  margin: 0;
}
.swiper-controls {
  display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.swiper-button-next, .swiper-button-prev {
  height: 36px;
  bottom: unset;
  top: unset;
  left: unset;
  right: unset;
  position: relative;
  margin: 0;
  width: 36px;
}
.swiper-button-next {
  transform: rotate(180deg);
}
.swiper-button-next:after, .swiper-button-prev:after {
  background: transparent url(../img/btn_arrow_left.svg) no-repeat center;
  background-size: 36px;
  color: rgba(0,0,0,0);
  display: block;
  height: 36px;
  font-size: 0;
  line-height: 0;
  width: 36px;
}
.swiper-pagination {
  position: relative;
  top: unset;
  left: unset !important;
  right: unset;
  bottom: unset !important;
  transform: unset;
  width: auto !important;
  margin: 0 10px;
}
.swiper-pagination-bullet {
  background-color: #E1E2EB;
  height: 10px;
  opacity: 1;
  width: 10px;
}
.swiper-pagination-bullet-active {
  background-color: #FFAA8A;
}
